<template>
  <div>
    <div class="container" style="margin-bottom:200px;">
      <div class="mt-1" style="text-align:center"><h5 style="padding-top:3px;">Notes Report</h5></div>
      <div class="mt-1" v-if="offices.length>0"><h6 style="padding-top:3px;"><strong>Offices:</strong> {{offices.map(item=>{return item.name}).join(", ")}}</h6></div>
      <div class="mt-1" v-if="clients.length>0"><h6 style="padding-top:3px;"><strong>Clients:</strong> {{clients.map(item=>{return item.name}).join(", ")}}</h6></div>
      <div class="row">
        <div class="col-12 bg-light border border-dark border-padding" style="margin-bottom:3px"> 

          <table class="table-sm table table-border m-0 pt-1">
            <thead>
              <tr>
                <th scope="col">Subject</th>
                <th scope="col">Date</th>
                <th scope="col">Client</th>
                <th scope="col">Office</th>
              </tr>
            </thead>
            <tbody>
              <tr v-bind:key="note.id" v-for="note in tableData">
                <td class="bt" style="font-weight: bold; color:#0e5499">{{note.title}}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{note.created_at | moment("MM-DD-YYYY") }}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{note.corporate.name}}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{note.office.office_name}}</td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import officeUser from "@/services/api/officeUser";
import note from "@/services/api/note";

export default {
  data() {
    return {
      data:[],    
      tableData: [],
      offices: [],
      clients: [],
    };
  },
  mounted() {
    this.offices=JSON.parse(this.$route.query.offices);
    this.clients=JSON.parse(this.$route.query.clients);
    switch (this.$store.getters.discriminator) {
      case "administrator":
        this.discriminator = "administrator";
        note.getNotes().then(response => {
            this.data = Object.assign([], response);
            this.filterData();
          });
        break;
      case "office":
      case "employee":
        officeUser.getOffice(this.$store.getters.id).then(response => {
            let idOffice=response[0].office.id;
            note.getNotesOffice(idOffice).then(response => {
              this.data = Object.assign([], response);
              this.filterData();
            });
        });
        break;
    }
    this.download();
  },
  methods: {
    download() {
      this.fullscreenLoading = false;
      setTimeout(() => {
        this.$nextTick(() => {
          window.print();
        });
      }, 1000);
    },
    filterData(){

      let idOffices = this.offices.map(office=>office.id);
      let clients = this.clients.map(client=>client.id);

      this.tableData = Object.assign([], this.data);

      if(idOffices.length>0 && idOffices.length!==this.lengthOfficeFilter){
        this.lengthOfficeFilter=idOffices.length;
        this.tableData=this.tableData.filter(item=>idOffices.indexOf(item.officeId)!=-1);
      }
      if(idOffices.length==0){
        this.lengthOfficeFilter=0;
        this.clientFilter=[];
        this.clients=[];
      }

      if(clients.length>0){
        this.tableData=this.tableData.filter(item=>clients.indexOf(item.corporate.id)!=-1);
      }
      
    }
  }
};
</script>

<style lang="scss">

table {
  border-collapse: separate; /* the default option */
  border-spacing: 0; /* remove border gaps */
}


tr {
   line-height: 10px;
   min-height: 10px;
   height: 10px;
}


.bt {
  border-top: 1px dotted black !important;
}

.bb{
  border-bottom: 1px dotted black !important;
}

td{
  font-size: 9px;
}

.border-padding{
  padding-top: 2px;
  padding-bottom: 2px;
}

@page 
    {
        size: auto;   /* auto is the initial value */
        margin: 0mm;  /* this affects the margin in the printer settings */
    }
  


</style>